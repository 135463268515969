<template>
  <div class="rtl">
    <router-link to="/accountingManage/ExchangeInventory">
      <button class="col-2 btn-dark">مشاهده موجودی های حسابداری</button>
    </router-link>
    <div class="row my-3">
      <label for="buyPrice" class="col-2 ">مشاهده موجودی کاربر</label>
      <div class="col-3">
        <v-text-field label="نام کاربری" v-model="userNameSelected" outlined @click="usersSearchShow = !usersSearchShow" @input="usersSearchShow = true" @blur="bluringUserSearchInput()"></v-text-field>
      </div>
      <v-btn @click="getAssetsInventory()">جست و جو</v-btn>
      <label for="buyPrice" class="col-2 col-form-label text-white">گزارش از تمام کیف پولها</label>
      <div class="col-2">
        <a :href="'/api/getUserInventoryExcel/'+'usersSearchQuery'" class="redButton fs-8" style="margin-top: 35px;">دریافت
          فایل XLS</a>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label>نمایش موجودی کیف پول صرافی</label>
      </div>
      <div>
        <v-btn @click="getInventory()">نمایش </v-btn>
      </div>
    </div>
    <div class="row my-5">
      <label for="buyPrice" class="col-2 col-form-label text-white">گزارش موجودی صرافی</label>
      <div class="col-3">
        <select id="accounting_coin_options" class="custom-select bg-panel m-0" v-model="cryptoSelected" @change="GetTotalBalanceInInternalAccountingSystemAndBlockchainByAssetId()">
          <option value="" selected disabled hidden>لطفا انتخاب کنید</option>
          <option :value="item.id" v-for="(item,index) in assets" :key="index">{{ item.persianTitle }}</option>
        </select>
      </div>
      <label for="buyPrice" class="col-2 col-form-label text-white">گزارش از تمام کیف پولها</label>
      <div class="col-2">
        <a @click="getExportTotalBalanceInInternalAccountingSystemAndBlockchain()" class="redButton fs-8">دریافت فایل XLS</a>
      </div>
    </div>

    <div class="row">
      <div class="col-7">
        <div class="row my-5">
          <label for="buyPrice" class="col-2 col-form-label text-white">مدیریت بازارها</label>
          <label for="buyPrice" class="col-2 col-form-label text-white">انتخاب تالار</label>
          <div class="col-4">
            <select id="accounting_rial_coin_options" class="custom-select bg-panel m-0" v-model="pairSelected" @change="GetBOpenOrdersQuickView()">
              <option value="" selected disabled hidden>لطفا انتخاب کنید</option>
              <option :value="pair.id" v-for="(pair,index) in pairs" :key="index">{{ pair.persianTitle }}</option>
            </select>
          </div>
          <router-link to="SpotOrdersQuickView">
            <v-btn>نمایش سریع کل بازار ها</v-btn>
          </router-link>
        </div>
        <div class="row my-1">

          <label for="buyPrice" class="col-4 col-form-label text-white">تعداد افراد از صف فروش:<span style="float:left">{{
            OpenOrdersQuickView.sellOrdersCount
          }}</span></label>
        </div>
        <div class="row my-1">

          <label for="buyPrice" class="col-4 col-form-label text-white">بالاترین قیمت فروش: <span style="float:left">{{ OpenOrdersQuickView.sellOrdersMaxPrice }}</span></label>
          <label for="buyPrice" class="col-4 col-form-label text-white">پایین ترین قیمت فروش:<span style="float:left">{{ OpenOrdersQuickView.sellOrdersMinPrice }}</span></label>
        </div>
        <div class="row my-1">

          <label for="buyPrice" class="col-4 col-form-label text-white">تعداد افراد در صف خرید:<span style="float:left">{{ OpenOrdersQuickView.buyOrdersCount }}</span></label>
        </div>
        <div class="row my-1">

          <label for="buyPrice" class="col-4 col-form-label text-white">بالاترین قیمت خرید:<span style="float:left">{{ OpenOrdersQuickView.buyOrdersMaxPrice }}</span></label>
          <label for="buyPrice" class="col-4 col-form-label text-white">پایین ترین قیمت خرید:<span style="float:left">{{ OpenOrdersQuickView.buyOrdersMinPrice }}</span></label>
        </div>
      </div>
    </div>
    <Popup :show.sync="showUserInformation">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">موجودی کاربر
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <v-row v-if="assetsInventory != ''">
            <v-col>
              <v-list-item>نام : {{assetsInventory[0].user.firstName }}</v-list-item>
            </v-col>
            <v-col>
              <v-list-item> نام خوانوادگی : {{assetsInventory[0].user.lastName }}</v-list-item>
            </v-col>
            <v-col>
              <v-list-item> نام کاربری : {{assetsInventory[0].user.username }}</v-list-item>
            </v-col>
          </v-row>
          <v-card>
            <v-card-title>
              موجودی
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" outlined hide-details class="searchStyle"></v-text-field>
            </v-card-title>
            <v-data-table :headers="headerInventory" :items="assetsInventory" :search="search">
            </v-data-table>
          </v-card>
        </v-card-text>
      </template>
    </Popup>
    <Popup :show.sync="showAssetInventory">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">موجودی صرافی
        </v-toolbar>
        <v-card-text class="rtl">
          <slot name="body"></slot>
          <div>
            <div class="modal-content form-group" style="width: auto">
              <div style="padding: 4%">
                <p style="font-size: 15px">جزعیات استعلام</p>
              </div>
              <div class="col-12" style="height: 40px;background-color: #252432;color: white; transition: 0.3s;direction: ltr;font-size: 13px;padding-top: 9px">
                <div class="row">
                  <div class="col-2">تاریخ</div>
                  <div class="col-3">موجودی بلاک چین</div>
                  <div class="col-3">موجودی داخلی</div>
                  <div class="col-3">نماد</div>
                </div>
              </div>
              <div class="col-12" style="height: 300px;overflow: auto;background-color: #252432;color: white; transition: 0.3s;direction: ltr;font-size: 13px;margin-top: 1%">
                <div class="row enterReport" style="height: 40px;padding-top: 5px;padding-bottom: 8px;margin-bottom: 10px">
                  <div class="col-2" v-if="this.lastBlockchainUpdateAtUtc != ''">
                    {{ this.lastBlockchainUpdateAtUtc != null || '' ? getPersianDate(this.lastBlockchainUpdateAtUtc) : 'تاریخ در دسترس نیست' }}
                  </div>
                  <div class="col-3">{{ this.totalBalanceInBlockchain }}</div>
                  <div class="col-3">{{ this.totalBalanceInInternalAccountingSystem }}</div>
                  <div class="col-3">{{ this.symbol }}</div>
                </div>
              </div>
              <hr style="border-top-color: white">
            </div>
          </div>
        </v-card-text>
      </template>
    </Popup>
  </div>
</template>
<script>
import axiosApi from "@/axios";
import axios from "axios";
import Popup from "@/components/Popup";
import Vue from "vue";
import getPersianDateTime from "@/plugins/getPersianDate";
import getEnv from "@/utils/getEnv";

export default {
  name: "AccountingManage",
  components: {
    Popup
  },
  data() {
    return {
      usersSearchShow: false,
      usersSearchQuery: '',
      userSearchResult: '',
      userNameSelected: '',
      assetsInventory: [],
      assets: '',
      cryptoSelected: '',
      pairSelected: '',
      lastBlockchainUpdateAtUtc: '',
      totalBalanceInBlockchain: '',
      totalBalanceInInternalAccountingSystem: '',
      symbol: '',
      pairs: '',
      OpenOrdersQuickView: [],
      showUserInformation: false,
      showAssetInventory: false,
      headerInventory : [
        {text: 'نام', value: 'asset.persianTitle'},
        {text: 'نماد', value: 'asset.symbol'},
        {text: 'دارایی', value: 'totalFund'},
        {text: 'دارایی قابل معامله', value: 'totalTradable'},
      ],
      search: ''

    }
  },
  mounted() {
    this.getassets()
    this.getPairs()
  },
  methods: {
    bluringUserSearchInput() {
      setTimeout(() => {
        this.usersSearchShow = false
      }, 150)
    },
    usersSearchResult() {
      if (this.usersSearchQuery) {
        return this.users.filter((item) => {
          return this.usersSearchQuery.toLowerCase().split(' ').every(v => item.userName.toLowerCase().includes(v))
        })
      } else {
        return this.users;
      }
    },
    getAssetsInventory() {
      if (this.userNameSelected === '') {
        Vue.$toast.open({
          message: 'لطفا شناسه کاربری را وارد کنید',
          type: 'error',
          position: 'top-right'
        })
        return
      }
      this.showUserInformation = true
      axiosApi().post('/api/Manage/v1/Wallet/GetList', {
        username: this.userNameSelected
      }).then(({data}) => {
        this.assetsInventory = data.data
      })
    },
    getInventory() {
      this.showUserInformation = true
      axiosApi().post('/api/Manage/v1/Wallet/GetList', {
        username: 'SystemBuiltinInternalExchangeFeeCollector'
      }).then(({data}) => {
        this.assetsInventory = data.data
      })
    },
    getassets() {
      axiosApi().post('api/Manage/v1/Asset/GetList')
          .then(({data}) => {
            if (data['isSuccess'] === true)
              this.assets = data.data
          })
    },
    GetTotalBalanceInInternalAccountingSystemAndBlockchainByAssetId() {
      axiosApi().post('/api/Manage/v1/Report/GetTotalBalanceInInternalAccountingSystemAndBlockchainByAssetId', {
        id: this.cryptoSelected
      }).then(({data}) => {
        if (data['isSuccess']) {
          this.lastBlockchainUpdateAtUtc = data.data.lastBlockchainUpdateAtUtc
          this.totalBalanceInBlockchain = data.data.totalBalanceInBlockchain
          this.totalBalanceInInternalAccountingSystem = data.data.totalBalanceInInternalAccountingSystem
          this.symbol = data.data.asset.symbol
          this.showAssetInventory = true
        }
      })
    },
    getPersianDate(date) {
      return getPersianDateTime(date);
    },
    GetBOpenOrdersQuickView() {
      axiosApi().post('/api/Manage/v1/Board/GetBOpenOrdersQuickView', {
        "pairId": this.pairSelected
      })
          .then(({data}) => {
            this.OpenOrdersQuickView = data.data
          })

    },
    getPairs() {
      axiosApi().post('api/Manage/v1/Pair/GetList')
          .then(({data}) => {
            if (data['isSuccess'] === true)
              this.pairs = data.data
          })
    },
    getExportTotalBalanceInInternalAccountingSystemAndBlockchain()
    {
      axios({
        url: `https://managerapi.${getEnv()}.ir/api/Manage/v1/Report/ExportTotalBalanceInInternalAccountingSystemAndBlockchain`, //your url
        // url: 'https://managerapi.narwhal.ir/api/Manage/v1/Report/ExportTotalBalanceInInternalAccountingSystemAndBlockchain', //your url
        method: 'POST',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    }


}
}
</script>

<style scoped>

</style>
